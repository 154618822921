import {atom} from 'recoil';
import {getCookie} from "../Cookies";

export const studentLogoState = atom({
    key: 'studentLogoStateKey',
    default: null
})

export const LogoOnState = atom({
    key: 'LogoOnStateKey',
    default: false
})

const stAcCode = getCookie('studentCodeAndAcademyCode')

export const HeaderAcademyName = atom({
    key: 'HeaderAcademyNameKey',
    default: stAcCode && stAcCode
})

export const selectedPeuExamState = atom({
    key: 'selectedPeuExamStateKey',
    default: ''
})

export const evaluationTableDataState = atom({
    key: 'evaluationTableDataStateKey',
    default: []
})

export const evaluationExplanationsDataState = atom({
    key: 'evaluationExplanationsDataStateKey',
    default: []
})

export const headerCookieChk = atom({
    key: 'headerCookieChkKey',
    default: false
})

export const examAnswerInfinityPageNumber = atom({
    key: 'examAnswerInfinityPageNumberKey',
    default: 1
})

export const examAnswerInfinityNumberLimit = atom({
    key: 'examAnswerInfinityNumberLimitKey',
    default: true
})

export const homeActiveTab = atom({
    key: 'homeActiveTabKey',
    default: 0
})
