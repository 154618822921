import React, {useEffect} from "react";
import {useCookies} from "react-cookie"
import {useNavigate} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRightFromBracket, faBars} from "@fortawesome/free-solid-svg-icons";
import {getCookie, setCookie} from "../../Util/Cookies";
import {HeaderAcademyName, headerCookieChk} from "../../Util/RecoilState/State";
import {useRecoilState} from "recoil";

const Header = (callback, deps) => {
    const [, , removeCookie] = useCookies(['X-AUTH-TOKEN']);
    const navigate = useNavigate();
    const loginState = getCookie('loginState')
    const [cookieChk, setCookieChk] = useRecoilState(headerCookieChk)
    const [academyName, setAcademyName] = useRecoilState(HeaderAcademyName)

    const logOut = () => {
        removeCookie("X-AUTH-TOKEN", { path: '/wnote' })
        removeCookie('loginState', { path: '/wnote' })
        removeCookie('studentCodeAndAcademyCode', { path: '/wnote' })
        removeCookie('student_info', { path: '/wnote' })
        removeCookie('API_KEY', { path: '/wnote' })

        removeCookie("X-AUTH-TOKEN", { path: '/' })
        removeCookie('loginState', { path: '/' })
        removeCookie('studentCodeAndAcademyCode', { path: '/' })
        removeCookie('student_info', { path: '/' })
        removeCookie('API_KEY', { path: '/' })

        navigate('/login');
        // u_memory_number는 지우지않음 (재로그인시 확인하기 위한 값)
    };

    const toggleLayerBox = (event) => {
        const buttonBox = event.currentTarget.nextElementSibling;
        const layer = event.currentTarget.parentElement;

        const handleClickOutside = (e) => {
            if (!layer.contains(e.target)) {
                buttonBox.classList.remove('on');
                document.removeEventListener('click', handleClickOutside);
            }
        };

        if (!buttonBox.classList.contains('on')) {
            buttonBox.classList.add('on');
            document.addEventListener('click', handleClickOutside);
        } else {
            buttonBox.classList.remove('on');
            document.removeEventListener('click', handleClickOutside);
        }
    };

    const academyChange = (academy_id, s_idx, a_academy_name) => {
        // 현재 학원과 이름이 같을 경우, 쿠키 값을 설정하지 않고 종료
        if (a_academy_name === academyName.a_academy_name) {
            return;
        }

        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 90);
        setCookie('studentCodeAndAcademyCode', {
            'academy_id': academy_id,
            's_idx': s_idx,
            'a_academy_name' : a_academy_name
        }, {expires: expirationDate})
        setCookieChk(!cookieChk)
        const layerElement = document.querySelector('.layer');
        if (layerElement) {
            layerElement.classList.remove('on');
        }
    }

    useEffect(()=>{
        const stAcCode = getCookie('studentCodeAndAcademyCode')
        setAcademyName(stAcCode)
    },[cookieChk, setAcademyName])


    return (
        <>
            <header className="header">
                <div className="left">
                    <h3 className='academy_name'>{academyName && academyName.a_academy_name}</h3>
                    {Object.keys(loginState.user.u_academy).length === 1 ?
                        null :
                        <>
                            <button onClick={toggleLayerBox}><FontAwesomeIcon icon={faBars} /></button>
                            <div className="layer">
                                {loginState.user.u_academy.map((data, idx)=>(
                                    <button onClick={()=>academyChange(data.ID ,data.s_code, data.a_academy_name)} key={idx}>{data.a_academy_name}</button>
                                ))}
                            </div>
                        </>
                    }

                </div>
                <div className="right">
                    <span onClick={toggleLayerBox}>{loginState.user.u_name} 학생 <FontAwesomeIcon icon={faArrowRightFromBracket} style={{marginLeft:'4px'}} /></span>
                    <div className="layer">
                        <button onClick={logOut}>로그아웃</button>
                    </div>
                </div>
            </header>
        </>

    )
}

export default Header;