import {Outlet} from "react-router-dom";

const LayoutType2 = () => {
    return (
        <>
            <Outlet/>
        </>
    )
}

export default LayoutType2;