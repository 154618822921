import React from "react";
import {ClipLoader} from "react-spinners";

// const override = {
//     display: "flex",
//     margin: "0 auto",
//     borderColor: "#716FCF",
//     textAlign: "center",
// };

const LoadingSpinner = ({ loading }) => {
    return (
        <div className='absoluteCenter'>
            <ClipLoader
                loading
                color="#716FCF"
                cssOverride={{}}
                size={50}
            />
        </div>
    );
};

export default LoadingSpinner;