import React, {useEffect, useState} from "react";
import {getCookie, setCookie} from "../Util/Cookies";
import {LogoOnState} from "../Util/RecoilState/State";
import {useRecoilValue} from "recoil";
import {useNavigate} from "react-router-dom";
import {getFormattedTimestamp} from "../Util/ComponentsUtill/timeParam";
import LoadingSpinner from "../Components/Card/LodingSpinner";

const Splash = () => {
    const [isSplashVisible, setIsSplashVisible] = useState(true);
    const studentData = getCookie('loginState')
    const [logoVisible, setLogoVisible] = useState(true);
    const [isLogoEntered, setIsLogoEntered] = useState(false)
    const logoOnState = useRecoilValue(LogoOnState) // 정식으로 로그인 으로 타고넘어오면 true 아니면 false
    const stAcCode = getCookie('studentCodeAndAcademyCode')
    const navigate = useNavigate();

    const closeSplash = (academy_id, s_idx, a_academy_name) => {
        // 인증코드 입력안해도 3개월 (90일) 동안은 학생 입력값 가지고있을 수 있게
        const expirationDate = new Date();
        expirationDate.setDate(expirationDate.getDate() + 90);
        setCookie('studentCodeAndAcademyCode', {
            'academy_id': academy_id,
            's_idx': s_idx,
            'a_academy_name' : a_academy_name
        }, {expires: expirationDate})
        setIsSplashVisible(false);
        navigate('/')
    };

    useEffect(()=>{
        setIsLogoEntered(true)
        let stAcCode = getCookie('studentCodeAndAcademyCode')
        // 단일 학원 다니는 학생으로 로그인 했을때
        if ((stAcCode && stAcCode.academy_id) && (stAcCode && stAcCode.s_idx) && (studentData && Object.keys(studentData.user.u_academy).length === 1)) {
            // navigate('/login')
        } else {

        }
    },[navigate, stAcCode, studentData])

    useEffect(()=>{
        if(logoOnState) {
            const timer = setTimeout(() => {
                setLogoVisible(false);
                if(studentData && Object.keys(studentData.user.u_academy).length === 1) {
                    navigate('/')
                }
            }, 3000);

            return () => {
                clearTimeout(timer);
            };
        }
        else {
            if(studentData && Object.keys(studentData.user.u_academy).length === 1) {
                navigate('/')
            }
            setLogoVisible(false)
        }
    },[logoOnState, navigate, studentData])

    useEffect(() => {
        const splashVisibleCookie = getCookie("isSplashVisible");
        if (splashVisibleCookie !== undefined) {
            setIsSplashVisible(false);
        } else {
            setIsSplashVisible(true)
        }
    }, []);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const image1 = new Image();
        image1.src = '/assets/img/multi_btmimg.png';
        const image2 = new Image();
        image2.src = '/assets/img/multi_bg.jpg';

        Promise.all([image1.decode(), image2.decode()]).then(() => {
            setIsLoading(false);
        });

    }, []);


    return (
        <>
        {isLoading ?
            <LoadingSpinner/>
            :
            <>

                {
                    studentData && Object.keys(studentData.user.u_academy).length > 1 ?
                        isSplashVisible && (
                            <div className={logoVisible ? 'splash_area' : 'splash_area off'}
                                 style={{
                                     // backgroundImage: `url("/assets/img/multi_btmimg.png"), url("/assets/img/multi_bg.jpg")`
                                     backgroundImage: `url("/assets/img/multi_btmimg.png")`
                                 }}
                            >
                                <img className={isLogoEntered ? 'on' : ''} src="/assets/img/multi_hand.png" alt="" />
                                <p>
                                    성적관리 및 성적향상 맛집에서
                                    <br />
                                    성적관리의 새로운 시작!
                                </p>
                                <div className="btn_box">
                                    {
                                        studentData.user.u_academy.map((data, idx) =>(
                                            <button key={idx} onClick={() => closeSplash(data.ID, data.s_code, data.a_academy_name)}>{data.a_academy_name}</button>
                                        ))
                                    }
                                </div>
                            </div>
                        )
                        :
                        null
                }
                {
                    studentData && Object.keys(studentData.user.u_academy).length === 1 ? (
                        <div
                            className={logoVisible ? 'splash_area_single' : 'splash_area_single off'}
                            style={{
                                // backgroundImage: `url("/assets/img/multi_btmimg.png"), url("/assets/img/multi_bg.jpg")`
                                backgroundImage: `url("/assets/img/multi_btmimg.png")`
                            }}
                            // style={{
                            //     backgroundImage: `url(${studentData && studentData.user.u_academy[0].d_skin}?ver=${getFormattedTimestamp()}`
                            // }} 각 학원별로 배경을 다르게 할 경우 주석 살려서 사용해야함
                        >
                            {studentData && Object.values(studentData.user.u_academy)[0].d_txt !== null ?
                                (() => {
                                    const text = `${Object.values(studentData.user.u_academy)[0].d_txt}`;
                                    const replacedText = text.replace(/<br \/>/g, '\n');
                                    return (
                                        <p>
                                            {replacedText}
                                        </p>
                                    );
                                })()
                                : null
                            }
                            {studentData && Object.values(studentData.user.u_academy)[0].d_logo &&
                                <img
                                    id="logoImg"
                                    src={`${studentData && Object.values(studentData.user.u_academy)[0].d_logo}?ver=${getFormattedTimestamp()}`}
                                    alt=""
                                />
                            }
                        </div>
                    ) : null
                }
            </>
        }


        </>
    );
};

export default Splash;
