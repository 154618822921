import React, {useEffect, useState} from 'react';
import './assets/css/reset.css'
import './assets/css/common.css';
// import './assets/css/common_test.css';
import Routers from "./Router/Routers";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {getCookie, setCookie} from "./Util/Cookies";
import axios from "axios";
import {getAxiosApi, SERVER_URL} from "./Util/Env";
import {useQuery} from "react-query";

function App() {

    const [showButton, setShowButton] = useState(false)
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }

    useEffect(() => {
        const handleShowButton = () => {
            if (window.scrollY > 200) {
                setShowButton(true)
            } else {
                setShowButton(false)
            }
        }


        window.addEventListener("scroll", handleShowButton)
        return () => {
            window.removeEventListener("scroll", handleShowButton)
        }
    }, [])

    const CustomCloseButton = ({ closeToast }) => {
        return (
            <button onClick={closeToast}>
                <img src={process.env.PUBLIC_URL + '/assets/img/ico_toast_delete.svg'} alt="Custom close icon" />
            </button>
        );
    };

    const fetchAPIKeyInfo = async () => {
        let studentCookie = getCookie('student_info')
        let param =  new URLSearchParams({
            'userName' : studentCookie.userName,
            'userCode' : studentCookie.userCode
        })

        const response = await axios.get(SERVER_URL + '/login/login?' + param.toString(), getAxiosApi());
        return response.data;
    }

    const {
        // eslint-disable-next-line no-unused-vars
        status: fetchAPIKeyInfoStatus,
        // data: fetchAPIKeyInfoData,
        // error: fetchAPIKeyInfoError,
    } = useQuery(
        ['fetchAPIKeyInfoKey'],
        () => fetchAPIKeyInfo(),
        {
            keepPreviousData: true,
            // staleTime: 5000,
            onSuccess: (data) => {
                const expirationDate = new Date();
                expirationDate.setDate(expirationDate.getDate() + 90);
                setCookie('API_KEY', data.data._D_MOBILE.token,{expires: expirationDate})
            },
        }
    );

    return (
          <>
              <button id="topBtn" className={showButton ? 'on' : ''} onClick={scrollToTop} type="button"><img src="/assets/img/arrow_top.svg" alt=""/></button>
              <Routers/>
              <ToastContainer
                  closeButton={<CustomCloseButton />}
                  position="top-center"
                  autoClose={3000}
                  hideProgressBar={true}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
              />
          </>
      );
}

export default App;
