import * as React from "react";
import {useEffect, useRef, useState} from "react";
import Select from "react-select";
import {reactSelectCustomStyles} from "../Util/reactSelectCustomStyles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown, faO, faPlus, faX} from "@fortawesome/free-solid-svg-icons";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {
    evaluationExplanationsDataState,
    evaluationTableDataState,
    examAnswerInfinityNumberLimit,
    examAnswerInfinityPageNumber,
    HeaderAcademyName,
    homeActiveTab,
    selectedPeuExamState
} from "../Util/RecoilState/State";
import {useNavigate} from "react-router-dom";
import {getCookie} from "../Util/Cookies";
import {useQuery} from "react-query";
import axios from "axios";
import {getAxiosApiKeyOption, SERVER_URL} from "../Util/Env";
import LoadingSpinner from "../Components/Card/LodingSpinner";
import Slider from "react-slick";
import {useCookies} from "react-cookie";

const TabMenu = () => {
    const [activeTab, setActiveTab] = useRecoilState(homeActiveTab);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

    return (
        <>
            <ul className="exam_tab_menu">
                <li className={activeTab === 0 ? "on" : ""} onClick={() => handleTabClick(0)}>
                    문제풀이 평가표
                </li>
                <li className={activeTab === 1 ? "on" : ""} onClick={() => handleTabClick(1)}>
                    출제 문제 및 해설보기
                </li>
            </ul>
            <div className="exam_tab_content">
                {activeTab === 0 && <TabContent1 />}
                {activeTab === 1 && <TabContent2 />}
            </div>
        </>
    );
};

const TabContent1 = () => {
    const evaluationTableData = useRecoilValue(evaluationTableDataState)
    const selectedPeuExam = useRecoilValue(selectedPeuExamState)

    return (
        <div className="exam_tab_conbox">
            <div className="exam_tab_table_top">
                <div className="left">
                    <FontAwesomeIcon icon={faPlus} />
                    <span>성취도 : {evaluationTableData && evaluationTableData.complete_per}</span>
                </div>
                <div className="right">
                    <span className='correct'>정답 : {evaluationTableData && evaluationTableData.o_cnt}</span>
                    <span className='incorrect'>오답 : {evaluationTableData && evaluationTableData.x_cnt}</span>
                </div>
            </div>
            <table className="exam_tab_table">
                <colgroup>
                    <col/>
                    <col style={{width:'70%'}}/>
                    <col/>
                    <col/>
                    <col/>
                    {(selectedPeuExam && selectedPeuExam.ep_paper_type) === 'WR' || (selectedPeuExam && selectedPeuExam.ep_paper_type) === 'RE' ?
                        null :
                        <col/>
                    }
                </colgroup>
                <thead>
                <tr>
                    <th>문번</th>
                    <th>출제 단원</th>
                    <th>난이도</th>
                    <th style={{padding:'4px 2px'}}>채점</th>
                    <th>배점</th>
                    {(selectedPeuExam && selectedPeuExam.ep_paper_type) === 'WR' || (selectedPeuExam && selectedPeuExam.ep_paper_type) === 'RE' ?
                        null :
                        <th>전체<br/>오답률</th>
                    }
                </tr>
                </thead>
                <tbody>
                {evaluationTableData && evaluationTableData.list.map((data, idx)=>(
                    <tr key={idx}>
                        <td>{data.peu_num}</td>
                        <td className="txt_l">{data.mc_subject}</td>
                        <td><span className="txt_level">{data.dpq_level}</span></td>
                        <td>
                            {data.dpq_OX === 'O' ? <FontAwesomeIcon icon={faO} /> : <FontAwesomeIcon icon={faX} />}
                        </td>
                        <td className='txt_r co1'>{data.dpq_score}</td>
                        {(selectedPeuExam && selectedPeuExam.ep_paper_type) === 'WR' || (selectedPeuExam && selectedPeuExam.ep_paper_type) === 'RE' ?
                                null :
                            <td className='txt_r'>
                                {data.all_x_per ? data.all_x_per : '0%'}
                            </td>
                        }
                    </tr>
                ))}

                </tbody>
            </table>
        </div>
    );
};

const TabContent2 = () => {
    const setPageNumber = useSetRecoilState(examAnswerInfinityPageNumber);
    const pageNumberLimit = useRecoilValue(examAnswerInfinityNumberLimit)

    const toggleButtonBox = (event) => {
        const buttonBox = event.currentTarget.parentElement;
        buttonBox.classList.toggle('on');
    };

    const explanationsData = useRecoilValue(evaluationExplanationsDataState);


    useEffect(() => {
        let isThrottled = false;

        const handleScroll = () => {
            if (!isThrottled) {
                isThrottled = true;
                setTimeout(() => {
                    const windowHeight = window.innerHeight;
                    const documentHeight = document.documentElement.scrollHeight;
                    const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;

                    if (documentHeight - (windowHeight + scrollTop) < 1 && pageNumberLimit) {
                        setPageNumber((prevPageNumber) => {
                            if (pageNumberLimit) {
                                return prevPageNumber + 1;
                            }
                            return prevPageNumber;
                        });
                    }

                    isThrottled = false;
                }, 500); // 0.5초에 한 번만 실행하도록 설정
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [pageNumberLimit, setPageNumber]);

    return (
        <div className="exam_tab_content">
            <ul className="answer_panner_list">
                {explanationsData &&
                    explanationsData.map((data, idx) => (
                        <li key={idx}>
                            <strong className={data.dpq_OX === 'O' ? 'num' : 'num wrong'}>{data.num}.</strong>
                            <div className="question_img_box">
                                <img src={data.qd_question} alt="" />
                            </div>
                            <div className="answer_box">
                                <div className="button_box">
                                    <button onClick={toggleButtonBox}>
                                        해설 보기 <FontAwesomeIcon icon={faAngleDown} />
                                    </button>
                                </div>
                                <div className="answer_img_box">
                                    <img src={data.qd_commentary} alt="" />
                                </div>
                            </div>
                        </li>
                    ))}
            </ul>
        </div>
    );
};

const Home = () => {

    const navigate = useNavigate();
    const stAcCode = getCookie('studentCodeAndAcademyCode')
    const academyState = useRecoilValue(HeaderAcademyName)
    const [classListOptions, setClassListOptions] = useState([])
    const [classListTeacher, setClassListTeacher] = useState([])
    const [selectedClass, setSelectedClass] = useState(null);
    const [selectedTeacher, setSelectedTeacher] = useState('');
    const [selectedTeacherGender, setSelectedTeacherGender] = useState('');
    const classRoomRef = useRef()
    const [selectedProfileImg, setSelectedProfileImg] = useState(null);
    const [selectedClCode, setSelectedClCode] = useState('');
    const [testDateListData, setTestDateListData] = useState(null)
    const [examListClickStatus, setExamListClickStatus] = useState('')
    const [selectedPeuExam, setSelectedExam] = useRecoilState(selectedPeuExamState)
    const setEvaluationTableData = useSetRecoilState(evaluationTableDataState)
    const setExplanationsData = useSetRecoilState(evaluationExplanationsDataState)
    const [pageNumber, setPageNumber] = useRecoilState(examAnswerInfinityPageNumber)
    const setPageNumberLimit = useSetRecoilState(examAnswerInfinityNumberLimit)
    const setActiveTab = useSetRecoilState(homeActiveTab)
    const [, , removeCookie] = useCookies(['X-AUTH-TOKEN']);

    const settings = {
        speed: 200,
        slidesToShow: 4,
        slidesToScroll: 4,
        focusOnSelect:true,
        infinite: testDateListData && testDateListData.length > 3 ? true : false,
        touchThreshold:30,
        centeredSlides:true,
        centerMode:true,
        arrows:false,
    };

    useEffect(()=>{
        if(stAcCode === undefined) {
            removeCookie("X-AUTH-TOKEN", { path: '/wnote' })
            removeCookie('loginState', { path: '/wnote' })
            removeCookie('studentCodeAndAcademyCode', { path: '/wnote' })
            removeCookie('student_info', { path: '/wnote' })
            removeCookie('API_KEY', { path: '/wnote' })

            removeCookie("X-AUTH-TOKEN", { path: '/' })
            removeCookie('loginState', { path: '/' })
            removeCookie('studentCodeAndAcademyCode', { path: '/' })
            removeCookie('student_info', { path: '/' })
            removeCookie('API_KEY', { path: '/' })

            navigate('/login');
        }
    },[navigate, stAcCode])

    // 접속정보 없으면 로그인으로 돌아가게 다른 페이지 추가로 생기면 이거 추가해주어야 직접 접근 막을 수 있음.
    useEffect(() => {
        // 옵션 값 변경 시 선택된 선생님 이름 업데이트
        if (selectedClass) {
            const selectedOption = classListTeacher.find(item => item.cl_code === selectedClass.value);

            if (selectedOption) {
                setSelectedTeacher(selectedOption.teacherName);
                setSelectedProfileImg(selectedOption.profileImg)
                setSelectedClCode(selectedOption.cl_code)
                setSelectedTeacherGender(selectedOption.mt_gender)
            }
        }

    }, [selectedClass, classListTeacher]);

    useEffect(() => {
        // 초기 선생님명 이름 세팅
        if (classListOptions.length > 0) {
            setSelectedClCode(classListOptions[0].value)
            setSelectedClass(classListOptions[0]);
            classRoomRef.current?.setValue(classListOptions.length > 0 ? classListOptions[0] : null)
        }

    }, [classListOptions]);

    useEffect(()=>{
        // 최초 선택된 선택 날짜 세팅
        if(testDateListData && testDateListData[0]) {
            // 캐싱 초기화 되서 use-query 에서 갱신할때 초기값 설정
            setActiveTab(0)
            let element = document.querySelector('.slick-track');
            if(element) {
                element.style.transform = 'translate3d(0px, 0px, 0px)';
            }
            window.scrollTo({
                top: 0,
            })
            setPageNumber(1)
            setPageNumberLimit(true)
            // 캐싱 초기화 되서 use-query 에서 갱신할때 초기값 설정 끝
            setExamListClickStatus(testDateListData[0].bce_code ? testDateListData[0].bce_code : testDateListData[0].ce_code && testDateListData[0].ce_code)
            setSelectedExam(testDateListData[0])
        }
    },[setActiveTab, setPageNumber, setPageNumberLimit, setSelectedExam, testDateListData])


    useEffect(() => {
        if (examListClickStatus && testDateListData) {
            const foundExam = testDateListData.find(
                data =>
                    (data.bce_code ? data.bce_code : data.ce_code) === examListClickStatus
            );
            
            setSelectedExam(foundExam);
        }
    }, [examListClickStatus, setSelectedExam, testDateListData]);


    // 수강반 조회
    const fetchStudentClassList = async () => {
        let param =  new URLSearchParams({
            'type' : 'class_list',
            'academy_id' : stAcCode && stAcCode.academy_id,
            's_idx' : stAcCode && stAcCode.s_idx
        })

        const response = await axios.get(SERVER_URL + '/api/exam?' + param.toString(), getAxiosApiKeyOption());
        return response.data;
    }

    const {
        status: fetchStudentClassListStatus,
        // data: fetchStudentClassListData,
        error: fetchStudentClassListError,
    } = useQuery(
        ['fetchStudentClassListQueryKey', academyState],
        () => fetchStudentClassList(),
        {
            keepPreviousData: true,
            // staleTime: 5000,
            onSuccess: (data) => {
            if(data.code === 403) {
                window.location.reload()
            }

            // discription 아닌 데이터만 배열화
            const filteredData = Object.values(data.data[0].list).filter(obj => obj !== data.data[0].discription);
            const classListData = filteredData
                .filter(obj => obj.ed_state === 'Y')
                .map(obj => ({
                    value: obj.cl_code,
                    label: obj.cs_subject
                }));

                const classTeacherData = filteredData.map(obj => {
                return {
                    cl_code: obj.cl_code,
                    teacherName: obj.mt_name + ' 선생님',
                    profileImg: obj.mt_photo,
                    mt_gender : obj.mt_gender
                };
            });
            setClassListOptions(classListData)
            setClassListTeacher(classTeacherData)

            },
        }
    );

    // 시험지 리스트 조회
    const fetchTestPaperList = async () => {
        let param =  new URLSearchParams({
            'type' : 'exam_list',
            'academy_id' : academyState && academyState.academy_id,
            's_idx' : academyState && academyState.s_idx,
            'cl_code' : selectedClCode
        })

        const response = await axios.get(SERVER_URL + '/api/exam?' + param.toString(), getAxiosApiKeyOption());
        return response.data;
    }

    // eslint-disable-next-line no-empty-pattern
    const {
        // status: fetchTestPaperListStatus,
        // data: fetchTestPaperListData,
        // error: fetchTestPaperListError,
    } = useQuery(
        ['fetchTestPaperListQueryKey', selectedClCode],
        () => fetchTestPaperList(),
        {
            keepPreviousData: true,
            // staleTime:Infinity,
            onSuccess: (data) => {
                if(data.code === 403) {
                    window.location.reload()
                }
                const filteredData = data.data && data.data[0].list.filter(obj => obj.view_YN === 'Y');
                // setTestDateListData(data.data && data.data[0].list)
                setTestDateListData(filteredData)
            },
        }
    );


    // 문항별 통계(일일제로 / 오답유사)
    const fetchQuestionStatisticsList = async () => {
        let param = new URLSearchParams({
            type: 'exam_report',
            academy_id: academyState && academyState.academy_id,
            s_idx: academyState && academyState.s_idx,
        });

        if ((selectedPeuExam && selectedPeuExam.ep_paper_type === 'DA') || (selectedPeuExam && selectedPeuExam.ep_paper_type === 'ZR')) {
            param.append('peu_code', selectedPeuExam.peu_code);
            param.append('ep_paper_type', selectedPeuExam.ep_paper_type);
        } else {
            param.append('bce_code', examListClickStatus && examListClickStatus);
        }

        const response = await axios.get(
            SERVER_URL + '/api/exam?' + param.toString(),
            getAxiosApiKeyOption()
        );

        return response.data;
    };

    // eslint-disable-next-line no-empty-pattern
    const {
        // status: fetchQuestionStatisticsListStatus,
        // data: fetchQuestionStatisticsListData,
        // error: fetchQuestionStatisticsListError,
    } = useQuery(
        ['fetchQuestionStatisticsListKey', selectedPeuExam, examListClickStatus],
        () => fetchQuestionStatisticsList(),
        {
            keepPreviousData: true,
            // staleTime:Infinity,
            onSuccess: (data) => {
                if(data.code === 403) {
                    window.location.reload()
                }
                setEvaluationTableData(data && data.data && data.data[0])
            },
        }
    );

    // 문제 및 해설
    const fetchExplanationsList = async () => {
        let param = new URLSearchParams({
            type: 'exam_answer',
            academy_id: academyState && academyState.academy_id,
            gp: pageNumber,
            ps: 3, // 한번에 요청할 개수
        });

        if (selectedPeuExam.ep_paper_type === 'DA' || selectedPeuExam.ep_paper_type === 'ZR') {
            param.append('ce_code', selectedPeuExam.ce_code);
            param.append('peu_code', selectedPeuExam.peu_code);
        } else {
            if(selectedPeuExam.bce_code !== undefined) {
                param.append('bce_code',  selectedPeuExam.bce_code);
            }
        }

        const response = await axios.get(
            SERVER_URL + '/api/exam?' + param.toString(),
            getAxiosApiKeyOption()
        );


        return response.data;
    };

    // eslint-disable-next-line no-empty-pattern
    const {
        // status: fetchExplanationsListStatus,
        // data: fetchExplanationsListData,
        // error: fetchExplanationsListError,
    } = useQuery(
        ['fetchExplanationsListKey', selectedPeuExam, pageNumber],
        () => fetchExplanationsList(),
        {
            keepPreviousData: true,
            // staleTime:Infinity,
            onSuccess: (data) => {
                if (data.code === 403) {
                    window.location.reload();
                }

                if (data.data && data.data[0].success_report_view && data.data && data.data[0].success_report_view.length === 0) {
                    setPageNumberLimit(false)
                }

                setExplanationsData((prevData) => {
                    let newData = (prevData && [...prevData]) || [];
                    const successReportView = data.data && data.data[0].success_report_view;

                    if (successReportView) {
                        const exists = successReportView.some((successItem) =>
                            newData.some((item) => item.num === successItem.num)
                        );

                        if (!exists) {
                            newData = [...newData, ...successReportView];
                        } else {
                            newData = [...successReportView];
                        }
                    }

                    return newData;
                });

            },
        }
    );



    if (fetchStudentClassListStatus === 'loading') {
        return <LoadingSpinner />;
    }

    if (fetchStudentClassListError === 'error') {
        return <p>Error: {fetchStudentClassListError.message}</p>;
    }


    function getClassByPaperType(paperType) {
        switch (paperType) {
            case 'ZR':
                return 'co1';
            case 'DA':
                return 'co2';
            case 'WR':
                return 'co3';
            case 'RE':
                return 'co4';
            default:
                return '';
        }
    }

    const examListClick = (onStatus, peu_code, ep_paper_type) => {
        if(examListClickStatus !== onStatus) {
            setExplanationsData([])
            setExamListClickStatus(onStatus)
            setPageNumber(1)
            setPageNumberLimit(true)
        }
    }

    return (
        <>
            {classListOptions.length > 0 && classListTeacher.length > 0 ?
                <div className="main_top_area">
                    <div className="top">
                        <div className="left">
                            <div className="img">
                                {selectedProfileImg !== '' ? (
                                    <img src={selectedProfileImg} alt="" />
                                ) : (
                                    <img
                                        src={
                                            selectedTeacherGender === 'M'
                                                ? '/assets/img/img_profile_boy.png'
                                                : '/assets/img/img_profile_girl.png'
                                        }
                                        alt=""
                                    />
                                )}
                            </div>
                        </div>
                        <div className="right">
                            <Select
                                styles={reactSelectCustomStyles}
                                options={classListOptions}
                                ref={classRoomRef}
                                onChange={setSelectedClass}
                                // menuIsOpen={true}
                            />
                            <p className='teacher'>{selectedTeacher}</p>
                        </div>
                    </div>
                    <div className="btm">
                        <ul>
                            {testDateListData && testDateListData.length !== 0 &&
                                <Slider {...settings}>
                                    { testDateListData &&
                                        testDateListData.length > 0 &&
                                        testDateListData.map((data, idx) => (
                                            <div
                                                id={data.bce_code ? data.bce_code : data.ce_code}
                                                key={idx}
                                                className={(data.bce_code ? data.bce_code : data.ce_code) === examListClickStatus ? `${getClassByPaperType(data && data.ep_paper_type)} on` : `${getClassByPaperType(data && data.ep_paper_type)}`}
                                                onClick={() => examListClick(data.bce_code ? data.bce_code : data.ce_code)}
                                            >
                                                {getClassByPaperType(data && data.ep_paper_type) !== 'co2' ? <span className='point'><img
                                                    src="/assets/img/ico_memo_point.svg" alt=""/> </span> : null}
                                                {data.dce_scoring_date.substring(5, 10).replace('-', '/')}
                                            </div>
                                        ))
                                    }
                                </Slider>
                            }

                        </ul>
                    </div>
                    <div className="exam_title">
                        <img src="/assets/img/ico_exampp.png" alt=""/>
                        <div className="tit_box">
                                <span className={`sub_tit ${getClassByPaperType(selectedPeuExam && selectedPeuExam.ep_paper_type)}`}>
                                  {selectedPeuExam && selectedPeuExam.ep_sub_title}
                                </span>
                            {selectedPeuExam && selectedPeuExam.ep_main_title}
                        </div>
                    </div>
                </div>
                :
                null
            }

            {
                testDateListData && testDateListData.length > 0 &&
                    <div className="main_btm_area">

                        <TabMenu/>
                    </div>
            }
            {
                testDateListData && testDateListData.length === 0 &&
                <div className='no_test_box'>
                    <img src="/assets/img/no_test_img.png" alt=""/>
                    <p><strong> {classListOptions.length > 0 && classListTeacher.length > 0 ? '응시한 시험지 정보가' : '등록된 반 정보가'} </strong>없습니다.</p>
                    <span>정보가 등록 되면 확인 하실 수 있습니다.</span>
                </div>
            }

        </>
    )
}

export default Home;