const brandColor = '#e8e8e8';
const brandColorFocus = '#e8e8e8'

export const reactSelectCustomStyles = {
    control: (base, state) => ({
        ...base,
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: state.isFocused ? brandColor : base.borderColor,
        '&:hover': {
            borderColor: state.isFocused ? brandColor : base.borderColor,
        }
    }),
    option: (base, { isFocused, isSelected }) => ({
        ...base,
        backgroundColor: isSelected ? brandColor : isFocused ? brandColorFocus : base.backgroundColor,
        color: isSelected ? '#000' : base.color,
        '&:hover': {
            backgroundColor: isSelected ? brandColor : brandColorFocus,
        },
    })
};

