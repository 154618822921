import {getCookie, setCookie} from "../Util/Cookies";
import {useNavigate} from "react-router-dom";
import {useForm} from 'react-hook-form';
import {useEffect, useRef, useState} from "react";
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {getAxiosApi, getAxiosApiKeyOption, SERVER_URL} from "../Util/Env";
import axios from "axios";
import {useToastAlert} from "../Util/ComponentsUtill/ToastAlert";
import {useSetRecoilState} from "recoil";
import {LogoOnState} from "../Util/RecoilState/State";

const Login = () => {
    const {
        toastNoticeSuccess,
        toastNoticeError,
    } = useToastAlert();

    const loginCookies = getCookie('loginCookie')
    const navigate = useNavigate();
    const [showTimer, setShowTimer] = useState(false);
    const [timer, setTimer] = useState(180); // 180 = 3분
    const [isResending, setIsResending] = useState(false);
    const intervalRef = useRef(null);
    const setLogoOnState = useSetRecoilState(LogoOnState)
    const [temporaryAuthKey, setTemporaryAuthKey] = useState('')
    const authenticationCodeRef = useRef()


    const formSchema = yup.object({
        userName: yup
            .string()
            .required('학생이름을 입력해주세요.')
            .test('is-korean', '학생 이름은 한글만 입력하세요.', value => {
                if (value) {
                    const regex = /^[ㄱ-ㅎㅏ-ㅣ가-힣]+$/;
                    const hasKoreanCharacters = regex.test(value);
                    const hasOnlyInitialConsonant = value.length === 1 && /[ㄱ-ㅎ]/.test(value);
                    return hasKoreanCharacters || hasOnlyInitialConsonant;
                }
                return true;
            }),
        userCode: yup
            .string()
            .required('학생코드를 입력해주세요.')
            .matches(/^[0-9]+$/, '학생 코드는 숫자만 입력하세요.'),
    });
    const {
        register,
        handleSubmit,
        watch,
        // setValue,
        formState: { errors},
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(formSchema),
        defaultValues: { // 초기값 설정
            // id: '',
            // age: '',
        }
    });

    useEffect(() => {
        if (showTimer && timer === 0) {
            setShowTimer(false);
            setIsResending(true);
        }
    }, [showTimer, timer]);

    const startTimer = () => {
        if (intervalRef.current) {
            return;
        }

        const interval = setInterval(() => {
            setTimer((prevTimer) => {
                if (prevTimer <= 1) {
                    clearInterval(interval);
                    setShowTimer(false);
                    setIsResending(true);
                    return 0;
                }
                return prevTimer - 1;
            });
        }, 1000);

        intervalRef.current = interval; // 인터벌 id ref에 저장
    };

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // 엔터 키 기본 동작 방지
            authorizationSubmit();
        }
    };

    // 로그인 버튼
    const onSubmit = (data) => {
        let param =  new URLSearchParams({
            'userName' : data.userName,
            'userCode' : data.userCode
        })

        let studentInfo =  {
            'userName' : data.userName,
            'userCode' : data.userCode
        }


        axios.get(SERVER_URL + '/login/login?' + param.toString(), getAxiosApi()).then(res => {
            if(res.data.type === 'success'){
                const expirationDate = new Date();
                expirationDate.setDate(expirationDate.getDate() + 90);
                setCookie("loginState", res.data.data._D_MOBILE, {expires: expirationDate});
                setCookie('API_KEY', res.data.data._D_MOBILE.token,{expires: expirationDate})
                setCookie('student_info', studentInfo,{expires: expirationDate})
                if(Object.values(res.data.data._D_MOBILE.user.u_academy).length === 0) {
                    return toastNoticeError('재원중인 학생이 아닙니다. 확인해주세요.')
                }
                // 쿠키에 저장된 인증키랑 로그인시 응답받은 키랑 일치할 경우(기존에 문자발송 응답값 일치해서 로그인 성공했던 경우) 홈으로 바로 이동
                if(getCookie('u_memory_number') === res.data.data._D_MOBILE.user.u_memory_number) {
                    // 인증코드 입력안해도 3개월 (90일) 동안은 로그인 가능하게 설정
                    setCookie('X-AUTH-TOKEN', res.data.data._D_MOBILE.user.u_memory_number, {expires: expirationDate})
                    navigate('/splash')
                    setLogoOnState(true)
                    let loginState = getCookie('loginState')
                    if(Object.keys(loginState.user.u_academy).length === 1) {
                        // 단일 학원 로그인시 인증키 확인 할 때 /api/exam/에 academy_id 값하고 s_idx 값 보내기위함
                        setCookie('studentCodeAndAcademyCode', {
                            'academy_id': Object.values(loginState.user.u_academy).length > 0 && Object.values(loginState.user.u_academy)[0].ID,
                            's_idx': Object.values(loginState.user.u_academy).length > 0 && Object.values(loginState.user.u_academy)[0].s_code,
                            'a_academy_name' : Object.values(loginState.user.u_academy).length > 0 && Object.values(loginState.user.u_academy)[0].a_academy_name
                        }, {expires: expirationDate})
                        navigate('/splash')
                    }

                } else {
                    const parentLayerDiv = document.querySelector('.parent_layer');
                    parentLayerDiv.classList.add('on');
                    const phoneNumberInput = document.querySelector('.parent_layer input[type="text"]');
                    phoneNumberInput.value = res.data.data._D_MOBILE.user.u_pphone // 부모님 휴대폰 번호로 변경
                    const phoneNumberValue = phoneNumberInput.value;
                    const hiddenPhoneNumber = phoneNumberValue.slice(0, -2) + '**';
                    phoneNumberInput.value = hiddenPhoneNumber;
                }
            } else if(res.data.type === 'danger'){
                toastNoticeError(res.data.msg)
            }
        }).catch(err => {
            console.log(err);
        });


    }

    const onError = () => {

    }
    // 문자 발송
    const handleSendButton = () => {
        const loginState = getCookie('loginState')

        let param =  new URLSearchParams({
            'a_code' : Object.values(loginState.user.u_academy).length > 0 && Object.values(loginState.user.u_academy)[0].a_code,
            'userCode' : loginState.user.u_code,
            'userMPhone' : loginState.user.u_pphone,
            'testYN' : "Y" // 테스트용 나중에 지워야함
            // 'userMPhone' : loginState.user.u_pphone.replace(/-/g, '') // - (하이픈) 빼서 달라고 하시면 이렇게
        })

        axios.get(SERVER_URL + '/login/num_auth?' + param.toString(), getAxiosApiKeyOption()).then(res => {
            if(res.data.type === 'success'){
                // 인증번호 발송당시에 오는 값을 저장해놨다가 인증 완료되면 해당 값을 쿠키에 저장하기 위해 임시 저장
                setTemporaryAuthKey(res.data.data)
                setShowTimer(true);
                setTimer(180);
                setIsResending(true);
                startTimer();
                toastNoticeSuccess('인증번호를 발송하였습니다.')
                if (res.data.msg === '문자발송 성공(테스트용)') { // 테스트용 차후에 지워야함
                    authenticationCodeRef.current.value = res.data.data;
                }
            } else if(res.data.type === 'danger'){
                toastNoticeError(res.data.msg)
            }
        }).catch(err => {
            console.log(err);
        });

    };

    // 문자 발송 후 인증키 확인
    const authorizationSubmit = () => {
        const loginState = getCookie('loginState')
        let param =  new URLSearchParams({
            'userCode' : loginState.user.u_code,
            'u_memory_number' : authenticationCodeRef.current?.value
        })

        axios.get(SERVER_URL + '/login/num_auth_ck?' + param.toString(), getAxiosApiKeyOption()).then(res => {
            if(res.data.type === 'success'){
                // 인증코드 입력안해도 3개월 (90일) 동안은 로그인 가능하게 설정
                const expirationDate = new Date();
                expirationDate.setDate(expirationDate.getDate() + 90);
                setCookie('X-AUTH-TOKEN', loginState.user.u_memory_number, {expires: expirationDate})
                setCookie('u_memory_number', temporaryAuthKey, {expires: expirationDate})
                setLogoOnState(true)
                if(Object.keys(loginState.user.u_academy).length === 1) {
                    // 단일 학원 로그인시 인증키 확인 할 때 /api/exam/에 academy_id 값하고 s_idx 값 보내기위함
                    setCookie('studentCodeAndAcademyCode', {
                        'academy_id': Object.values(loginState.user.u_academy).length > 0 && Object.values(loginState.user.u_academy)[0].ID,
                        's_idx': Object.values(loginState.user.u_academy).length > 0 && Object.values(loginState.user.u_academy)[0].s_code,
                        'a_academy_name' : Object.values(loginState.user.u_academy).length > 0 && Object.values(loginState.user.u_academy)[0].a_academy_name
                    }, {expires: expirationDate})
                    navigate('/splash')
                }
                // 다중 로그인시
                else {
                    navigate('/splash')
                }
            } else if(res.data.type === 'danger'){
                if(res.data.msg === '필수 파라메터 오류') {
                    toastNoticeError('인증코드를 입력해주세요.')
                } else {
                    toastNoticeError(res.data.msg)
                }

            }
        }).catch(err => {
            console.log(err);
        });

    }




    return (
        <>
            <div className="login_area">
                <div className="login_tit">
                    <p>디지털학습의 새로운 시작!</p>
                    <h1>Login</h1>
                </div>
                <form id='loginForm' name='loginForm' onSubmit={handleSubmit(onSubmit, onError)}>
                    <div className="login_box">
                        <div className="error_box">
                        <div className={'input_flex ' + (errors.userName ? "error " : "") + (watch().userName ? "success " : "")}>
                            <span className='tit'>학생이름</span>
                            <input
                                tabIndex="1"
                                autoComplete="off"
                                name="userName"
                                type="text"
                                placeholder="학생 이름을 입력하세요."
                                {...register('userName')}
                                // onInput={(e) => {
                                //     e.target.value = e.target.value.replace(/[^ㄱ-ㅎㅏ-ㅣ가-힣]/g, '');
                                // }}
                                // value='문동현'
                            />
                        </div>
                            {errors.userName && <div className="error_tip">{errors.userName.message}</div>}
                        </div>
                        <div className="error_box">
                        <div className={'input_flex ' + (errors.userCode ? "error " : "") + (watch().userCode ? "success " : "")}>
                            <span className='tit'>학생코드</span>
                            <input
                                type="tel"
                                placeholder="학생 코드를 입력하세요."
                                name="userCode"
                                maxLength="9"
                                {...register('userCode')}
                                autoComplete="off"
                                onInput={(e) => {
                                    e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                }}
                                // value='232727128'
                            />
                        </div>
                            {errors.userCode && <div className="error_tip">{errors.userCode.message}</div>}
                        </div>
                        <div className={loginCookies ? 'parent_layer on' : 'parent_layer'}>
                            <div className="input_flex">
                                <span className="tit">보호자 휴대폰</span>
                                <input type="text" defaultValue="" readOnly />
                                {isResending ? (
                                    <button type='button' onClick={handleSendButton}>재발송</button>
                                ) : (
                                    <button type='button' onClick={handleSendButton}>발송</button>
                                )}
                            </div>
                            <div className="input_flex">
                                <span className="tit">인증코드 입력</span>
                                <input
                                    type="tel"
                                    placeholder="인증 코드"
                                    maxLength="6"
                                    onInput={(e) => {
                                        e.target.value = e.target.value.replace(/[^0-9]/g, '');
                                    }}
                                    ref={authenticationCodeRef}
                                    onKeyDown={handleKeyDown}
                                />
                                {showTimer && <span className="timer">{formatTime(timer)}</span>}
                                <button type="button" onClick={authorizationSubmit}>
                                    확인
                                </button>
                            </div>
                        </div>
                    </div>
                    <button className='btn_login'>로그인</button>
                </form>
                {/*<div className="footer">*/}
                {/*    ⓒ PSEBOOKS*/}
                {/*</div>*/}
            </div>
        </>
    )
}

export default Login;